@use "@angular/material" as mat;
@import "@angular/material/theming";

@include mat.core();

/* You can add global styles to this file, and also import other style files */
$iwifi-brand: (
  100: #0c67ff,
  200: #6d94ff,
  300: #7fa3d1,
  400: #6992c9,
  500: #5282c1,
  600: #4072b4,
  700: #38649d,
  800: #305687,
  900: #284770,
  A100: #ffffff,
  A200: #dde6f3,
  A400: #6992c9,
  A700: #38649d,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
  ),
);

$mobile-max: 768px;
$tablet-max: 1020px;
$desktop-min: 1021px;
$tablet-min: 769px;

$primary: mat-palette($iwifi-brand, 100);
$accent: mat.define-palette(mat.$blue-palette, 700);
$warn: mat.define-palette(mat.$red-palette, 800);

$typography: mat.define-typography-config(
  $font-family: "Roboto",
);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
    ),
    typography: $typography,
  )
);

@include mat.all-component-themes($theme);

$primary-color: mat.get-color-from-palette($primary, default);
$accent-color: mat.get-color-from-palette($accent, default);
$warn-color: mat.get-color-from-palette($accent, default);
$muted-color: #99abb4;

html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font-family: "Roboto", sans-serif;
}

.wrapper {
  height: 100%;

  @media only screen and (min-width: $tablet-min) {
    height: 100vh;
  }

  display: flex;
  flex-direction: column;
}

.centered {
  margin: 0;
  height: 100vh;
  display: flex;
  background-size: cover !important;
  align-items: center;
  justify-content: center;
}

.email-sent-note {
  background: #e0ecff;
  color: #0c67ff;
  font-size: 14px;
  font-weight: 600;
  padding: 16px;
  border-radius: 3px;
  line-height: 20px;
  margin-bottom: 16px;
}

.page-container {
  // top: 64px;
  // padding-top: 64px;
  position: relative;
  min-height: calc(100% - 64px);
  // overflow: auto;
  // height: calc(100% - 64px);
}

.mat-simple-snackbar-action button {
  padding: 0px 16px !important;
  color: #5897ff;
}

.upload-attachment {
  margin-top: 6px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.uploaded-attachment {
  padding: 10px 0px;

  > div {
    position: relative;
    width: fit-content;

    button {
      position: absolute;
      right: -16px;
      top: -16px;
    }

    img {
      height: 80px;
      width: 80px;
      object-fit: cover;
    }
  }
}

ngx-skeleton-loader {
  span {
    box-shadow: 0px 3px 6px 1px #c1c5e46b !important;
    height: 80px;
  }
}

.skeleton {
  width: auto;
  height: auto;
  margin: 10px auto;
  padding: 10px;

  @media only screen and (max-width: $mobile-max) {
    padding: 0px;
  }

  span {
    @media only screen and (max-width: $mobile-max) {
      box-shadow: 0px 3px 6px 1px #c1c5e46b !important;
      background: #edf1ff;
    }
  }
}

.full-width {
  width: 100%;
}

.light-banner {
  width: 100%;
  background: #e0ecff;
  padding: 16px;
  margin-bottom: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .container {
    flex: 1;
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    color: $primary-color;
  }

  .desc {
    font-size: 12px !important;
    margin-top: 2px;
    line-height: 18px;
    color: #8196b3 !important;
    font-weight: 500 !important;
  }
}

//Content Container

.content-container {
  max-width: 2000px;
  margin: 0 auto;
  padding: 16px;

  @media only screen and (max-width: $mobile-max) {
    padding-bottom: 48px;
  }

  &.form-page {
    @media only screen and (max-width: $mobile-max) {
      padding: 0px 0px 24px;
    }
  }

  &.with-create-foot {
    // padding-bottom: 0px !important;
    // @media only screen and (max-height: 600px) {
    //  padding-bottom: 0px !important;
    // }
  }

  &.with-total-foot {
    padding-bottom: 80px;
  }

  &.with-search {
    @media only screen and (max-width: $mobile-max) {
      // padding-top: 72px;
      padding: 0px;

      .listing {
        margin-top: 58px;
        margin-bottom: 0px;

        .mat-list {
          max-height: calc(100vh - 124px);
          padding: 16px;
        }
      }
    }
  }

  &.with-float {
    padding-top: 0px;
    padding-bottom: 0px;

    @media only screen and (max-width: $mobile-max) {
      .listing {
        .mat-list {
          padding-bottom: 82px;
        }
      }

      .card-list,
      .doc-list {
        padding-bottom: 82px;
      }
    }
  }

  &.setting {
    padding-top: 16px;
  }

  &.medium {
    max-width: 1000px;
  }

  &.small {
    max-width: 700px;
  }

  &.xx-small {
    max-width: 500px;
  }
}

//Content Container

//Login

.sign-up-account {
  margin-top: 20px;
  padding: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;

  span {
    cursor: pointer;
    color: #fabb18;
    text-decoration: underline;
  }
}

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  color: white;
  background: linear-gradient(180deg, #6913cc 10%, #2674fc);

  @media (min-width: $desktop-min) {
    .logo-container {
      margin-bottom: 20px;

      font-weight: bold;
    }
  }

  @media (max-width: 1024px) {
    background: white;

    .login-form {
      width: 100% !important;
      padding: 0px !important;
    }

    .crm-info {
      display: none !important;
    }

    .logo-container {
      text-align: center;
      background: linear-gradient(180deg, #6913cc 10%, #2674fc);
      border-radius: 0px 0px 20px 20px;
      padding: 28px 0px;
      text-align: center;

      div {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .login-form {
    width: 100%;
    padding: 50px;
    align-items: center;

    .login-logo {
      width: 250px;
      display: block;
      margin: 0 auto 20px;
    }

    .mat-card {
      box-shadow: none !important;
      max-width: 600px;
      margin: 0 auto;

      @media (min-width: $desktop-min) {
        padding: 24px !important;
      }
    }

    .sign-in-text {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 24px;
    }

    .forgot-password-link {
      font-size: 14px;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
      position: absolute;
      right: 24px;
      z-index: 1;
      margin-top: 3px;
    }

    section .mat-checkbox-label {
      font-size: 14px !important;
      font-weight: 500 !important;
      white-space: normal;
    }

    .mat-raised-button {
      margin-top: 20px;
      width: 100%;
      background: #fabb18;
      border-radius: 10px;
      color: black;
    }
  }

  .crm-info {
    width: 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 100px;

    .title {
      font-weight: 900;
      font-size: 50px;
      margin-bottom: 20px;
      color: white;
      line-height: 60px;
    }

    .desc {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff96;
    }
  }
}

//Login

//Drawer
.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-drawer-inner-container:hover {
  // overflow-y: auto !important;
  // overflow-x: hidden;
}

.main-content {
  height: 100%;
  flex: 1;

  .drawer-content {
    width: 320px;

    &:hover {
      ::-webkit-scrollbar-track,
      ::-webkit-scrollbar,
      ::-webkit-scrollbar-thumb {
        visibility: unset;
        position: absolute;
      }
    }

    ::-webkit-scrollbar-track {
      background-color: white;
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 17px;
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #dfdfdf;
      border: 5.5px solid white;
      border-radius: 10px;
      visibility: hidden;
    }

    .mat-expansion-panel {
      margin-bottom: 6px;
      box-shadow: none !important;

      .mat-expansion-panel-header {
        // background: #e4e4e4 !important;
        padding: 0px 16px 0px 0px;
      }

      .mat-expansion-panel-body {
        padding: 6px 0px 16px;
      }

      .mat-expansion-panel-content {
        padding-left: 24px;
      }
    }

    .mat-nav-list {
      padding: 12px 16px 128px 16px;
      margin-top: 16px;
      overflow-y: auto !important;
      height: calc(100vh - 64px);

      .mat-list-item {
        &:hover {
          background: #f2f2f2 !important;
        }

        &.active {
          background: #f2f2f2 !important;

          .title,
          .mat-icon {
            color: #121212;
            // font-weight: bold;
          }
        }

        border-radius: 5px;
        height: unset;
        margin-bottom: 6px;

        .mat-list-item-content {
          padding: 8px 12px !important;
        }
      }

      .mat-icon {
        color: #a6a6a6;
      }

      .label-container {
        padding: 10px 16px;
        border-radius: 10px;
      }

      .label-title {
        font-size: 14px;
        color: #212121;
        font-weight: 700;
      }

      .label-desc {
        font-size: 12px;
        color: #686868;
      }

      .title {
        color: #a6a6a6;
        font-weight: 500;
      }
    }
  }

  .mat-drawer {
    // background-color: #1b1b28;
    background-color: #ffffff;
  }

  .mat-drawer-content {
    background-color: #f2f2f2;
  }

  .mat-drawer-side {
    border-right: none;
  }

  .logo-container {
    height: 64px;
    text-align: center;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 160px;
    }
  }
}

.login-other {
  text-align: center;
  width: 100%;

  .login-other-text {
    padding: 24px;
    font-size: 12px;
    font-weight: 600;
    color: grey;
  }

  button {
    max-width: 44px !important;
    max-height: 44px !important;
    min-width: 44px;
    padding: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content !important;
    display: inline-block;
    background: white !important;
    margin: 0px 8px !important;

    img {
      width: 100%;
    }
  }

  .facebook {
    background: #1877f2 !important;
    padding: 8px !important;
  }
}

.login-user {
  margin-bottom: 30px;
  text-align: center;

  mat-icon {
    position: absolute;
    margin-top: -2px;
    cursor: pointer;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 30px 0px 10px;
    background: #eef0f8;
    border-radius: 50%;
    border: 1px solid #eef0f8;
  }

  .name {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }

  .email {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #a6a6a6;
  }
}

//Drawer

//Header
.page-header {
  right: 0px;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-transform: translateZ(1px);
  -moz-transform: translateZ(1px);
  -o-transform: translateZ(1px);
  transform: translateZ(1px);
  -webkit-transform: translate3d(0, 0, 0);

  @media only screen and (min-width: $tablet-min) {
    display: none !important;
  }

  .page-header-title {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    width: 100%;
    position: relative;
    top: 1px;
  }

  .mat-icon-button {
    border-radius: 10px;
    border: 2px solid #b0b3be;

    .mat-icon {
      top: -4px;
      position: relative;
    }
  }
}

.header {
  top: 0;
  z-index: 1;
}

.header,
.page-header {
  background: white;
  padding: 0px !important;
  position: sticky;
  // z-index: 1;
  right: 0px;

  .menu-container {
    padding: 0px 16px;
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;

    // box-shadow: 0px 0px 7px #c1c5e46b;
    .page-title {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 800;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 900;
      top: -2px;
      position: relative;
    }
  }
}

//Header

//Search Field
.search-container {
  display: flex;
  background: linear-gradient(180deg, #4743e3, #2870fa);
  padding: 4px 24px 24px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  position: sticky;
  top: 64px;
  z-index: 1;

  @media only screen and (min-width: $tablet-min) {
    display: none !important;
  }

  .search-wrapper {
    display: flex;
    flex: 1;
    border: 1px solid white;
    border-radius: 10px;
    align-items: center;
    color: white;
    padding: 10px;

    input {
      font-family: "Roboto", sans-serif;
      width: 100%;
      flex: 1;
      background: none;
      border: none;
      outline: unset;
      color: white;
      font-size: 16px;
      padding: 0px 10px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: white;
    }
  }

  .icon-text {
    color: #ffffff96;

    .mat-icon {
      color: #ffffff96;
    }
  }

  .date-picker-wrapper {
    padding: 2px 2px 0px;
    position: relative;
    border: 1px solid white;
    border-radius: 10px;
    align-items: center;
    color: white;
    margin-left: 16px;

    .mat-form-field-wrapper {
      padding-bottom: 0px;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix {
      display: none;
    }

    input {
      border: none;
      background: none;
      border: none;
      outline: unset;
      margin-left: 10px;
      font-family: "Roboto", sans-serif;
    }
  }
}

.search-field {
  width: 100%;
  background: black;
  height: 160px;
  display: flex;
  align-items: center;

  .mat-icon-button {
    height: 24px;
    width: 24px;
    line-height: 24px;

    mat-icon {
      font-size: 24px !important;
    }
  }

  @media only screen and (max-width: $mobile-max) {
    // display: none;
    height: unset;
    background: none;

    .search-container {
      padding: 0px !important;
      position: fixed;
      top: 64px;
      z-index: 2;
      box-shadow: 0px 3px 6px 1px #c1c5e46b !important;
    }

    .search-area {
      width: 100%;

      .division {
        max-width: unset;
        border: unset;
        flex: 1;

        &:not(:first-child) {
          padding-left: 0px;
        }

        input {
          width: 100%;
        }
      }
    }

    .title {
      display: none;
    }

    .dropdown-container {
      flex: 0 !important;

      .label {
        display: none;
      }

      input {
        display: none;
      }

      .mat-icon {
        margin-right: 0px !important;
      }

      .mat-date-range-input {
        position: absolute;
        right: 0px;
        opacity: 0;
        top: 36px;
        width: fit-content;
        height: 1px;
      }
    }
  }

  .dropdown-container {
    & > .mat-icon-button {
      margin-right: 8px;
      position: relative;
      top: -2px;
    }

    .label {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: #a6a6a6;
      min-width: 150px;
      cursor: pointer;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    color: white;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .search-container {
    padding: 0px 16px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
}

.search-area {
  background: white;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  padding: 16px 0px;

  .division {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    border-right: 3px solid #a6a6a6;
    width: 100%;
    max-width: 300px;

    &:last-child {
      border: none;
    }

    .mat-date-range-input-start-wrapper {
      max-width: 100%;
      overflow: unset;
      padding-right: 6px;
    }

    .mat-icon {
      margin-right: 8px;
      color: #a6a6a6;
    }

    input {
      border: none;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }

    ::-webkit-input-placeholder {
      color: #a6a6a6;
    }

    input:focus {
      outline: none;
    }
  }
}

//Search Field

//Button

.bottom-sheet-btn {
  width: 100%;
  background: #fabb18;
  border-radius: 16px !important;
  margin-top: 12px !important;
}

.mat-raised-button {
  box-shadow: 0px 3px 6px 1px #c1c5e46b !important;
  font-size: 14px;
  padding: 16px !important;
  min-height: fit-content !important;
  line-height: 18px !important;
  font-weight: 700;

  &.float-btn {
    @media only screen and (min-width: $tablet-min) {
      display: none;
      // .mat-icon {
      //   display: none;
      // }
    }

    @media only screen and (max-width: $mobile-max) {
      background: #fabb18;
      z-index: 1;
      position: fixed;
      bottom: 16px;
      right: 16px;
      border-radius: 50%;
      height: 60px;
      min-width: 45px;
      padding: 0px !important;
      overflow: hidden;
      width: 60px;

      & > span > span {
        display: none;
      }
    }
  }

  &.mat-primary {
    color: white;
  }

  &.lg {
    min-width: 200px;
  }

  &.secondary {
    background: #e0ecff;
    color: #0c67ff;
  }

  &.sm {
    width: fit-content;
    font-size: 12px;
    line-height: 16px !important;
    padding: 10px 16px !important;
  }
}

button {
  &.sm {
    .mat-icon {
      font-size: 16px;
      height: 16px;
      width: 16px;
      line-height: 16px;
      margin-right: 4px;
      vertical-align: top;
    }
  }
}

.mat-icon-button {
  &.sm {
    height: 24px;
    width: 24px;
    line-height: 24px;

    mat-icon {
      height: 24px;
      width: 24px;
      line-height: 24px;
      font-size: 16px;
    }
  }
}

.mat-flat-button {
  font-size: 14px;
  padding: 16px !important;
  min-height: fit-content !important;
  line-height: 18px !important;
  font-weight: 700;

  &.mat-primary {
    color: white;
  }

  &.lg {
    min-width: 200px;
  }

  &.sm {
    width: fit-content;
    font-size: 12px;
    line-height: 16px !important;
    padding: 10px 16px !important;
  }
}

.mat-button {
  font-size: 14px;
  padding: 16px !important;
  min-height: fit-content !important;
  line-height: 18px !important;
  font-weight: 700;

  &.secondary {
    background: #e0ecff;
  }

  &.lg {
    min-width: 200px;
  }

  &.sm {
    width: fit-content;
    font-size: 12px;
    line-height: 16px !important;
    padding: 10px 16px !important;
  }
}

//Button

//Cards

.mat-card-header-text {
  margin: 0px !important;

  .mat-card-title {
    font-size: 16px;
    font-weight: 700;
  }
}

.mat-card {
  height: fit-content;
  margin: 10px 0px;
  box-shadow: 0px 3px 6px 1px #c1c5e46b !important;

  &.card-details {
    padding-bottom: 0px;
    margin-top: 0px;

    .mat-divider {
      margin: 0px -16px 16px;
      position: relative !important;
      width: calc(100% + 32px) !important;
    }
  }

  &.form-field {
    padding-bottom: 0px;
    margin-top: 0px;

    .mat-divider {
      margin: 0px -16px 16px;
      position: relative !important;
      width: calc(100% + 32px) !important;
    }
  }
}

.button-row {
  text-align: right;

  button:first-child {
    margin-right: 12px;
  }
}

//Cards

//List
.listing {
  .mat-card-header {
    padding: 16px 16px 0px;
  }

  .list-header {
    border-bottom: 3px solid #f2f2f2;
  }

  .mat-list-text {
    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      width: 200px;
    }

    @media (min-width: $desktop-min) {
      width: 400px;
    }
  }

  @media (max-width: $mobile-max) {
    margin-top: 0px;

    .icon-text-wrapper {
      display: flex;
      flex-wrap: wrap;

      .icon-text {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.mat-card {
    padding: 0px;

    @media (max-width: $mobile-max) {
      background: none;
      box-shadow: none !important;
    }
  }

  .mat-divider {
    @media (max-width: $mobile-max) {
      display: none;
    }
  }

  &.with-tab {
    .mat-list {
      @media (min-width: $tablet-min) {
        max-height: calc(100vh - 440px);
        overflow: auto;
      }

      .mat-list-item-content {
        // flex-wrap: wrap;
      }
    }
  }

  .mat-list {
    // max-height: calc(100vh - 400px);
    // overflow: auto;
    // @media (min-width: $tablet-min) {
    //   max-height: calc(100vh - 400px);
    //   overflow: auto;
    // }

    .mat-list-item-content {
      // flex-wrap: wrap;
    }
  }

  .mat-list-base {
    padding-top: 0px;
  }

  .header-col {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #a6a6a6;

    @media (max-width: $mobile-max) {
      display: none !important;
    }
  }

  .main-col {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;

    @media (min-width: $tablet-min) {
      min-width: 15%;
    }

    @media (max-width: $mobile-max) {
      padding-right: 0px;
    }
  }

  .account-col {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding-right: 8px;
  }

  .sm-col {
    width: 100px;
  }

  .medium-col {
    width: 150px;
  }

  .x-medium-col {
    width: 180px;
  }

  .x-lg-col {
    width: 200px;
  }

  .lg-col {
    width: 250px;
  }

  .bank-col {
    width: 200px;

    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      display: none;
    }
  }

  .status-col {
    width: 150px;
    text-align: center;

    @media (max-width: $mobile-max) {
      width: fit-content;
      min-width: fit-content;
    }

    .cover-label {
      margin: 0 auto;
    }
  }

  .sm-action-col {
    width: 40px;

    @media (max-width: $mobile-max) {
      width: fit-content;
      min-width: fit-content;
    }
  }

  .action-col {
    width: 150px;

    @media (max-width: $mobile-max) {
      width: fit-content;
      min-width: fit-content;
    }
  }

  img.mat-list-icon {
    padding: 0px !important;
    background: #a6a6a6;
    border-radius: 5px !important;
    height: 40px !important;
    width: 40px !important;
    object-fit: cover;
    // margin-right: 8px;
    border: 1px solid #cdd5f3;
  }

  .mat-list-item {
    height: unset !important;

    @media (max-width: $mobile-max) {
      background: white;
      box-shadow: 0px 3px 6px 1px #c1c5e46b !important;
      // margin-bottom: 14px;
    }

    .mat-list-item-content {
      padding: 16px !important;
      border-bottom: 1px solid #e3e3e3;
    }
  }

  &.mobile-no-gap {
    @media (max-width: $mobile-max) {
      background: white;
      box-shadow: 0px 3px 6px 1px #c1c5e46b !important;
    }

    .mat-list-item {
      @media (max-width: $mobile-max) {
        margin-bottom: 0px;
      }
    }

    .skeleton {
      padding: 0px 10px;
    }
  }
}

.card-list {
  .small-avatar {
    margin: 2px 6px 2px 0px;
    object-fit: contain !important;
    border: 2px solid #fff;
    box-shadow: 0px 0px 4px #00000069;
  }

  &.with-top-card {
    margin-top: -12px;
  }

  .mat-list-item {
    background: white;
    box-shadow: 0px 3px 16px 0 #c1c5e445 !important;
    margin-bottom: 10px;
    cursor: pointer;
    height: unset !important;
    border-radius: 15px;
    transition: all 0.3s;

    &:hover {
      // background: #e6f0ff;
    }

    &.warning {
      background: #ff0066;

      &:hover {
        background: #ec005f;
      }

      .mdTitle-f,
      .mat-icon {
        color: white;
      }

      .desc-f {
        color: #ffc9df;
      }
    }

    .mat-list-item-content {
      padding: 16px !important;
      // border-bottom: 1px solid #e3e3e3;
    }
  }
}

// List

//Cover Label
.table-list {
  &.aging {
    th,
    td {
      width: 20%;
    }
  }

  .mat-row {
    height: 56px;
  }

  .cover-label {
    width: 75px !important;
    display: inline-block;
    text-align: center;
    padding: 4px 0px;
  }

  .aging {
    th,
    td {
      width: 20%;
    }
  }

  td.mat-table-sticky-border-elem-left {
    box-shadow: 4px 4px 8px 0px #c1c5e46b !important;
  }

  .checkbox-col {
    min-width: 60px;
  }

  .name-col {
    min-width: 200px;
  }

  .medium-col {
    min-width: 100px;
  }

  .large-col {
    min-width: 150px;
  }

  .mat-footer-cell {
    .color-red {
      color: #ff477a !important;
    }
  }
}

.cover-label {
  &.sm {
    font-size: 12px;
  }

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  color: #0c67ff;
  background: #e0ecff;
  padding: 8px 12px;
  border-radius: 5px;

  &.user-status {
    @media (max-width: $mobile-max) {
      position: absolute;
      bottom: 10px;
      left: 2px;
    }
  }

  &.grey {
    color: #505050;
    background: #d5d5d5;
  }

  &.orange {
    color: #d19900;
    background: #f8ebc7;
  }

  &.red {
    color: #ff0022;
    background: #ffc5cd;
  }

  &.green {
    color: #009764;
    background: #99ebd0;
  }

  &.teal {
    color: #0096b5;
    background: #d9f9ff;
  }
}

//Cover Label

//Profile Function
.profile-func {
  display: flex;
  justify-content: center;

  & > div {
    cursor: pointer;
    height: 60px;
    width: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 16px 0px;
    background: #f4f4f4;

    .mat-icon {
      font-size: 32px;
      height: 32px;
      width: 32px;
      color: #020c31;
    }
  }
}

//Profile Function

//Colors
.color-grey {
  color: #505050;
}

.color-orange {
  color: #f9bb18 !important;
}

.color-red {
  color: #c6005f !important;
}

.color-green {
  color: #2fa09d !important;
}

.color-purple {
  color: #995cae !important;
}

.color-teal {
  color: #0096b5;
}

.color-blue {
  color: $primary-color;
}

.color-light-grey {
  color: #a6a6a6 !important;
}

.color-white {
  color: white;
}

.color-black {
  color: #121212;
}

//Colors

//Font
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.bsTitle-f {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 16px;
}

.hint-f {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  top: -12px;
  position: relative;

  b {
    font-weight: 900;
    color: black;
  }
}

.title-f {
  font-size: 20px;
  font-weight: 800;
  line-height: 26px;
}

.mdTitle-f {
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 22px;
}

.xsTitle-f {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20px;
}

.desc-f {
  font-size: 14px;
  font-weight: 500 !important;
  color: #a6a6a6;
  line-height: 20px;
}

.xsDesc-f {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #a6a6a6;
  line-height: 18px;
}

.xsValue-f {
  font-size: 12px;
  font-weight: 600 !important;
  color: #121212;
  line-height: 18px;
}

.highlight-text {
  font-weight: 700;
  color: #0c67ff;
}

.label-desc {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #a6a6a6;
}

.label-value {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.icon-text-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .icon-text:first-of-type {
    margin-right: 10px;
  }
}

.icon-text {
  transform: rotate(0.03deg);

  &.contact {
    background: #dff3ff;
    border: 1px solid white;
    color: #44535c;
    font-size: 12px !important;
    padding: 4px 8px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px #0b599e4f inset;
    cursor: pointer;
    width: fit-content;
  }

  .mat-icon {
    color: #a6a6a6;
    height: 14px;
    width: 14px;
    font-size: 14px;
    top: 2px;
    margin-right: 6px;
    position: relative;
  }

  font-size: 14px !important;
  font-weight: 500 !important;
  color: #a6a6a6;
}

//Font

//Dropdown Menu
.mat-menu-item .mat-icon {
  transform: rotate(0.03deg);
}

.mat-menu-item {
  line-height: 18px !important;
  height: fit-content !important;
  padding: 12px 16px !important;
  font-weight: 600;
  font-weight: 700;
  font-size: 14px;

  mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
    line-height: 18px;
    color: #a6a6a6 !important;
    margin-right: 12px !important;
  }

  &.active {
    background: #4743e31a;
    color: #4743e3;
  }

  border-bottom: 1px solid #e8e8e8 !important;

  &:last-child {
    border: none !important;
  }
}

.mat-menu-content:not(:empty) {
  padding: 0px !important;
}

.mat-menu-panel {
  box-shadow: 0px 0px 20px 7px #81787847;
}

//Dropdown Menu

//Form

.mat-form-field-wrapper {
  font-weight: 600;
}

.side-input {
  width: 150px;

  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
    font-size: 14px;
    font-weight: 600;
    margin: 0px !important;
    text-align: right;
  }

  .mat-form-field-infix {
    padding: 0.5rem 0 !important;
  }
}

.inform {
  width: 100%;

  &.left {
    width: 50%;
    padding-right: 8px;
  }

  &.right {
    width: 50%;
    padding-left: 8px;
  }

  &.mobile-full {
    @media (max-width: $mobile-max) {
      width: 100%;
      padding: 0px;
    }
  }
}

mat-label {
  font-size: 14px;
  font-weight: 600;
}

.form-field {
  &.with-sub-radio {
    .mat-radio-button {
      width: 100%;
      margin-bottom: 16px;
      margin-left: 24px;
      font-weight: 500;
      position: relative;
      top: -4px;

      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  section {
    width: 100%;

    .label-desc {
      margin-bottom: 4px;
    }

    .mat-checkbox {
      width: 100%;
      margin-bottom: 16px;

      &.left {
        width: 50%;
        padding-right: 8px;
      }

      &.right {
        width: 50%;
        padding-left: 8px;
      }
    }

    .mat-checkbox-label {
      font-size: 16px;
      font-weight: 500;
      color: #7a7a7a;
      line-height: 20px;
      position: relative;
      top: 1px;
    }
  }

  .mat-form-field {
    width: 100%;

    input,
    textarea,
    .mat-select-value-text {
      font-weight: 600;
    }

    .mat-form-field-label-wrapper {
      font-weight: 600;
    }

    &.left {
      width: 50%;
      box-sizing: border-box;
      padding-right: 8px;
    }

    &.right {
      width: 50%;
      box-sizing: border-box;
      padding-left: 8px;
    }

    &.mobile-full {
      @media (max-width: $mobile-max) {
        width: 100%;
        padding: 0px;
      }
    }

    .mat-form-field-suffix .mat-icon {
      color: #a6a6a6;
    }
  }

  .preview-img-container {
    position: relative;
    margin: 0 auto 20px;

    img {
      width: 90px;
      height: 90px;
      border: 4px solid white;
      box-shadow: 0px 0px 20px 1px #999ba9ab;
      border-radius: 50%;
      object-fit: contain;
      background: #fbfbfb;
    }

    button {
      position: absolute;
      bottom: 0px;
      right: 0px;
      background: #fabb18;
      box-shadow: none;
      border: 2px solid white;

      .mat-icon {
        color: #121212;
        position: relative;
        top: -2px;
      }
    }
  }

  .mat-card-content {
    display: flex;
    flex-wrap: wrap;
  }
}

.mat-radio-group {
  width: 100%;
  flex-wrap: wrap;
  display: flex;

  .mat-radio-button {
    font-weight: 600;
    margin-bottom: 12px;

    width: 100%;

    @media (min-width: $tablet-min) {
      flex: 1;
    }
  }
}

.mat-form-field-invalid {
  margin-bottom: 12px !important;
}

.ngx-mat-tel-input-container {
  font-weight: 600;
}

.mat-datepicker-content .mat-calendar {
  height: fit-content !important;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button,
.mat-datepicker-toggle .mat-icon-button {
  height: 24px !important;
  width: 24px !important;
  line-height: 24px;

  svg {
    height: 24px !important;
    width: 24px !important;
  }

  mat-icon {
    font-size: 24px !important;
  }

  svg {
    font-size: 24px;
    color: #a6a6a6;
  }
}

.mat-button.country-selector {
  margin-top: 10px;
  padding: 0px !important;
}

.mat-select-search-custom-header-content {
  font-weight: 600 !important;
  color: #ff0022;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none !important;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none !important;
}

::placeholder {
  /* Recent browsers */
  text-transform: none !important;
}

//Form

//Dialog
// .mat-dialog-container {
//   padding: 16px !important;
//   max-width: 500px;
//   min-width: 260px;
//   width: 85vw !important;

//   @media (min-width: $tablet-min) {
//     min-width: 350px;
//   }

//   .mat-divider {
//     width: calc(100% + 32px);
//     margin-left: -16px;
//     margin-bottom: 16px;
//   }

//   .form-field {
//     display: flex;
//     flex-wrap: wrap;

//     .mat-form-field {
//       width: 100%;

//       &.left,
//       &.right {
//         width: 50%;
//       }

//       &.mobile-full {
//         @media (max-width: $mobile-max) {
//           width: 100%;
//           padding: 0px;
//         }
//       }
//     }
//   }
// }

// .mat-dialog-content {
//   margin: 0 -16px !important;
//   padding: 0 16px !important;
// }

// .mat-dialog-title {
//   font-weight: 700;
//   font-size: 16px;
//   margin-bottom: 8px !important;
// }

// .mat-dialog-actions {
//   float: right;
//   margin-bottom: -16px !important;

//   .mat-button {
//     font-weight: 600;
//   }

//   &.with-divider {
//     margin-top: -16px !important;
//   }
// }

//Dialog

//Content Wrapper
.content-wrapper {
  display: flex !important;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    width: 100%;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 16px;
    }
  }

  .left {
    width: 50%;
    padding-right: 8px;

    @media (max-width: $mobile-max) {
      width: 100%;
      padding: 0px;
    }
  }

  .right {
    width: 50%;
    padding-left: 8px;

    @media (max-width: $mobile-max) {
      width: 100%;
      padding: 0px;
    }
  }

  .cover-img {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    border: 1px solid #dbe0e6;
    margin-bottom: 16px;
    border-radius: 5px;
  }

  .profile-img {
    width: 100%;
    height: 88px;
    width: 88px;
    border-radius: 50%;
    object-fit: contain;
    border: 4px solid #f2f2f2;
    margin-bottom: 16px;
    // box-shadow: 0px 0px 20px 1px #999ba9ab;
  }
}

//Content Wrapper

//Timeline

.timeline {
  margin-top: -16px;

  & > div {
    text-align: center;

    &:last-of-type {
      &::after {
        background-image: linear-gradient(
          #b9b9b9 50%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: right;
        background-size: 2px 10px;
        background-repeat: repeat-y;
        height: 38px;
        display: block;
        margin: 0 auto;
        text-align: center;
        width: 2px;
        content: "1";
        color: white;
      }
    }

    .cover-label {
      margin: 0 auto;
    }

    &::before {
      background-image: linear-gradient(#b9b9b9 50%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 2px 10px;
      background-repeat: repeat-y;
      height: 38px;
      display: block;
      margin: 0 auto;
      text-align: center;
      width: 2px;
      content: "1";
      color: white;
    }
  }
}

//Timeline

//Tab
.mat-tab-list {
  background: white;
}

.mat-tab-label-active {
  font-weight: 700;
  color: black;
  opacity: 1 !important;
}

.mat-tab-header-pagination-disabled {
  background: #e4e4e4;
}

//Tab

//mat-select-search
.mat-select-search-inner .mat-button {
  padding: 0px !important;
}

.mat-select-search-custom-header-content {
  padding: 15px;
  font-weight: 900;
}

//mat-select-search

//Bottom Sheet

.mat-bottom-sheet-container {
  padding: 12px 0px !important;
  border-top-left-radius: 32px;
  background: #fbfbfb;
  border-top-right-radius: 32px;
}

.bs-grey {
  height: 5px;
  width: 50px;
  background: #e8e8e8;
  margin: 0 auto 16px;
  border-radius: 16px;
}

.bs-black {
  height: 5px;
  width: 130px;
  background: #121212;
  margin: 16px auto 0px;
  border-radius: 16px;
}

//Bottom Sheet

.company-avatar {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
  width: 32px;
  border-radius: 100px;
  margin-right: 10px;
  border: 2px solid #dedcdc;
  object-fit: contain;

  &.active {
    padding: 1px;
    border: 2px solid #212121;
  }
}

.company-avatar.active + span {
  font-weight: bold;
}

.small-avatar {
  height: 20px;
  width: 20px;
  border-radius: 100px;
  border: 1px solid white;
}

.medium-avatar {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  // box-shadow: 0px 0px 14px #00000069;
  border: 2px solid white;
  object-fit: contain;
  background: white;
}

.m-r-12 {
  margin-right: 12px;
}

.select-icon {
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  margin-left: 6px !important;
  top: -2px;

  mat-icon {
    font-size: 20px;
    color: #a6a6a6;
    line-height: 17px !important;
    height: 20px;
    width: 20px;
    transform: rotate(0.03deg);
    top: 0px !important;
  }

  &.color-black {
    mat-icon {
      color: black;
    }
  }

  &.view {
    background: #fabb18;
    border-radius: 5px;

    mat-icon {
      color: black;
    }
  }
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.item-row {
  align-items: center;
  margin-bottom: 16px;
}

.save-checkbox {
  margin-bottom: 16px;

  mat-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    margin-right: 4px;
    transform: rotate(0.03deg);
    color: #a6a6a6;
    vertical-align: middle;
    top: -2px;
    position: relative;
  }

  .mat-checkbox-label {
    font-size: 14px;
    font-weight: 500;
    color: #a6a6a6;
  }
}

.total-item-row {
  border-top: 4px solid #f2f2f2;
  padding: 12px 0px 16px;
  margin-top: 22px;
}

.total-label {
  font-size: 20px;
  line-height: 26px;
  color: #a6a6a6;
  font-weight: bold;
}

.total-value {
  font-size: 20px;
  line-height: 26px;
  color: #121212;
  font-weight: bold;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-400 {
  font-weight: 400 !important;
}

.fs-14 {
  font-size: 14px !important;
}

.m-t-16 {
  margin-top: 16px;
}

.item-desc {
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-submit {
  background: white;
  padding: 12px 16px 8px;
  // position: fixed;
  // @media only screen and (max-height: 600px) {
  //   position: unset;
  // }
  bottom: 0px;
  left: 0px;
  width: 100%;
  flex-wrap: wrap;
  box-shadow: 0px -3px 16px 0 #9c9ea973 !important;

  @media only screen and (min-width: $tablet-min) {
    background: none;
    box-shadow: none !important;
    padding: 0px;
    position: relative;
    text-align: right;
    display: flex;
    flex-direction: row-reverse;
  }

  button {
    padding: 12px !important;
    line-height: 18px !important;
    height: fit-content;
    border-radius: 10px;
  }

  .save {
    background: #fabb18;
    width: 100%;

    @media only screen and (min-width: $tablet-min) {
      max-width: 150px;
    }
  }

  .draft {
    margin: 0 auto;
    display: block;

    @media only screen and (min-width: $tablet-min) {
      width: 100%;
      max-width: 150px;
      line-height: 18px !important;
      font-size: 14px;
      padding: 12px !important;
      display: inline;
      margin: 0px 16px 0px 0px;
    }

    left: 0;
    margin-top: 8px;
    font-size: 12px;
    padding: 8px 12px !important;
  }
}

.form-total {
  width: 100%;
  margin-bottom: 10px;

  .total-label {
    color: #00000078;
  }
}

.m-0 {
  margin: 0px !important;
}

.details-total {
  position: fixed !important;
  bottom: 0px;
  left: 0px;
  width: 100%;
  margin: 0px !important;
  box-shadow: 0px -3px 16px 0 #9c9ea973 !important;
}

.company-setting-icon .mat-icon {
  margin: 0px !important;
  height: 24px;
  width: 24px;
  font-size: 24px;
  line-height: 24px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-l-4 {
  margin-left: 4px;
}

.btn-toggle {
  font-size: 12px;
  font-weight: 600;
  background: #f4f4f4;
  padding: 4px 6px;
  border: none;
  margin: 0 auto;
  border-radius: 6px;

  .mat-button-toggle-checked {
    background: white !important;
    box-shadow: 0px 0px 14px #00000026;
  }

  .mat-button-toggle {
    background: none;
    border-radius: 4px;
    border: none !important;
  }

  .mat-button-toggle-label-content {
    line-height: 18px !important;
    min-width: 75px;
    padding: 8px 12px !important;
    background: none;
  }

  &.sm {
    font-size: 10px;

    .mat-button-toggle-label-content {
      line-height: 18px !important;
      min-width: 42px;
      padding: 6px 12px !important;
      background: none;
    }
  }
}

.default-icon {
  font-size: 20px;
  height: 20px !important;
  width: 20px !important;
  line-height: 20px;
  transform: rotate(0.03deg);
  color: #fabb18;
  vertical-align: middle;
  top: -2px;
  position: relative;
}

.month-end-box {
  margin-top: -14px;
  margin-bottom: 24px;
  font-size: 14px;
  color: #7a7a7a;
}

.mat-list-avatar {
  align-self: baseline;
}

.mat-list-item-with-avatar .mat-list-text {
  padding-left: 8px !important;
}

.shoebox-file {
  padding: 0px !important;
  margin: 0px !important;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 12px 4px #adafbffc !important;
  }

  .file-type {
    // background: #eef0f8;
    text-align: center;
    border-bottom: 2px solid #eef0f8;
    height: 120px;
    justify-content: center;
    align-items: center;
    display: flex;

    mat-icon {
      height: 50px;
      width: 50px;
      font-size: 50px;
      line-height: 50px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .mat-checkbox {
    position: absolute;
    right: 10px;
    top: 6px;
  }

  .mat-icon-button {
    height: 24px;
    width: 24px;
    line-height: 24px;
    margin-top: -4px;
    position: relative;
    top: 2px;
  }

  .xsTitle-f {
    padding: 16px;
  }
}

.shoebox-img {
  background: #f2f2f2;
  text-align: center;
  border-radius: 16px;
  margin-bottom: 10px;
  height: 80px;

  @media only screen and (min-width: $tablet-min) {
    height: 100px;
  }

  img {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }
}

.without-shadow {
  box-shadow: none !important;

  .mat-list-item {
    box-shadow: none !important;
  }
}

.mat-list-base {
  padding-top: 0px !important;
}

.mat-card.profile-card {
  box-shadow: none !important;
  background: none;
}

.m-r-16 {
  margin-right: 16px !important;
}

.yellow-btn {
  background: #f9bb18;
  color: #121212;
}

.show-overflow {
  white-space: initial;
}

.more-btn {
  background: white;
  border-color: white !important;

  .mat-icon {
    color: #121212 !important;
  }
}

.item-num {
  width: 14px;
  text-align: center;
  margin-right: 10px;
}

.listing .cdk-drag-handle {
  position: relative !important;
  top: -12px;
  left: -6px;
}

.m-l-6 {
  margin-left: 6px;
}

.visible-overflow {
  overflow: visible !important;
}

.cdk-drag-preview {
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 16px;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.p-l-24 {
  padding-left: 24px !important;
}

.empty-img {
  width: 100%;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid white;
  background: #fbfbfb;
  margin-bottom: 16px;
  box-shadow: 0px 0px 20px 1px #999ba9ab;
  display: flex;
  align-items: center;
  justify-content: center;

  mat-icon {
    font-size: 54px;
    height: 54px;
    width: 54px;
    line-height: 54px;
    color: #b0b3be;
  }
}

.add-contact-row {
  @media only screen and (max-width: $mobile-max) {
    padding: 0px 16px 16px;
  }

  .card-list .mat-list-item .mat-list-item-content {
    padding: 10px 16px !important;
  }
}

.mat-tab-header {
  border: none;
}

.profile-tab {
  .mat-tab-body-wrapper {
    padding-top: 16px;
  }

  .mat-ink-bar {
    background-color: #f9bb18 !important;
  }
}

.edit-company-btn {
  font-size: 12px;
  padding: 8px 12px !important;
  background: #ebebeb;
  border-radius: 10px !important;
  margin-top: 16px !important;
}

code-input {
  --item-spacing: 10px;
  --item-height: 6em;
  --item-border: none;
  --item-border-bottom: 2px solid #dddddd;
  --item-border-has-value: none;
  --item-border-bottom-has-value: 2px solid #888888;
  --item-border-focused: none;
  --item-border-bottom-focused: 2px solid #809070;
  --item-shadow-focused: none;
  --item-border-radius: 0px;
  text-align: center;

  input {
    width: 56px !important;
    height: 56px !important;
    border-radius: 15px !important;
    font-size: 24px !important;
    font-weight: bold !important;
  }
}

.verification-container {
  padding: 100px 16px;
  background-color: white;

  .verification-email {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: grey;
    text-align: center;

    b {
      color: black;
    }
  }
}

.mat-horizontal-stepper-header {
  flex: 1;
  padding: 0 16px !important;

  .mat-step-icon {
    width: 100%;
    border-radius: 10px;
    height: 5px;

    .mat-step-icon-content {
      display: none;
    }
  }
}

.mat-stepper-horizontal-line {
  display: none;
}

.company-setup {
  padding-top: 50px;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;

  .mat-horizontal-stepper-header-container {
    padding: 0px 10px;
  }

  .mat-horizontal-stepper-header {
    height: fit-content;
    padding: 0px 6px !important;
    margin: 36px 0px 24px;
    pointer-events: none !important;
  }

  .mat-step-header .mat-step-icon-selected {
    background-color: #0c67ff !important;
  }

  .mat-step-header .mat-step-icon {
    background-color: #020c313b;
  }

  // .mat-horizontal-stepper-header .mat-step-icon{
  //   background: #;
  // }
  .setup-desc {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 32px;
  }

  .mat-horizontal-content-container {
    padding: 0px !important;
  }
}

.upload-btn {
  font-weight: 600;
  width: 100%;
  margin-bottom: 16px !important;
  padding: 8px 0px !important;
}

.uploaded-file {
  width: 100%;

  & > div:last-child {
    margin-bottom: 16px;
  }

  .xsTitle-f {
    font-weight: 500 !important;
    align-items: center;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.account-list {
  background: none;

  .mat-tree-node {
    margin-bottom: 10px;
    background: white;
    background-clip: content-box;
    min-height: unset;
    height: 44px;
  }

  .xsTitle-f,
  .xsDesc-f {
    margin-left: 12px;
  }

  .seq-btn {
    background: #e7e7e7;
    border-radius: 0px;
    height: 44px;
    width: 44px;
  }
}

.table-list {
  ngx-skeleton-loader {
    position: relative;
    top: 16px;

    span {
      box-shadow: none !important;
      height: 40px !important;
      border-radius: 6px !important;
    }
  }

  box-shadow: 0px 3px 16px 0 #c1c5e445;

  .mat-table {
    width: 100%;

    thead {
      height: 56px;
    }

    tfoot {
      background-color: #3c3d3a;

      td {
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        box-shadow: none !important;
      }
    }

    th {
      border: none;
      font-size: 14px;
      font-weight: bold;
      color: #9f9f9f;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    td {
      border-bottom: 1px solid #e9e9e9;
    }

    .mat-header-row {
      height: unset;

      th {
        border-bottom: 2px solid #f2f2f2;
      }
    }

    .mat-row {
      // &:hover {
      //   cursor: pointer;
      //   background: #f9f9f9;
      // }
    }
  }
}

.table-search-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .search-wrapper {
    display: flex;
    align-items: center;
    background: #f2f2f2;
    padding: 10px 12px;
    border-radius: 10px;

    input {
      font-weight: 500;
      border: none;
      background: none;
      border: none;
      outline: unset;
      margin-left: 10px;
      font-family: "Roboto", sans-serif;
    }
  }

  .date-picker-wrapper {
    // display: flex;
    // align-items: center;
    position: relative;

    .mat-icon-button {
      margin-right: 4px;
      margin-left: 4px;
    }

    margin-left: 10px;
    background: #f2f2f2;
    border-radius: 10px;

    .mat-form-field-wrapper {
      padding: 0px !important;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix {
      border-top: 0px;
      padding: 16px 0px !important;
      top: 0px;
    }

    .mat-form-field-invalid {
      margin-bottom: 0px !important;
    }

    // .mat-form-field-appearance-legacy .mat-form-field-underline{
    //   display: none;
    // }
    // .mat-form-field-infix{
    //   display: none;
    // }
    .mat-form-field-label {
      top: 28px;
    }

    input {
      // border: none;
      // background: none;
      // border: none;
      // outline: unset;
      // margin-left: 10px;
      font-family: "Roboto", sans-serif;
    }

    .confirm-search {
      background-color: #0c67ff;
      color: white;
      margin-left: 0px;
      height: 34px;
      width: 34px;
      line-height: 34px;
      margin-right: 16px;
    }

    .confirm-search:disabled {
      background-color: #e0e0e0;
      color: #a6a6a6;
    }
  }

  .dropdown-wrapper {
    position: relative;
    margin-left: 10px;
    background: #f2f2f2;
    border-radius: 10px;

    .mat-form-field-wrapper {
      padding: 0px 16px !important;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix {
      border-top: 0px;
      padding: 16px 0px !important;
      top: 0px;
    }

    .mat-form-field-invalid {
      margin-bottom: 0px !important;
    }

    .mat-form-field-label {
      top: 28px;
    }

    .mat-select-arrow-wrapper {
      position: relative;
      top: -2px;
    }
  }
}

.remove-date {
  margin-right: -8px !important;
  margin-top: -8px !important;

  &.mat-icon-button {
    position: absolute !important;
    background: #f44336;
    right: 2px;
    height: 20px;
    width: 20px;

    .mat-icon {
      color: white;
      font-size: 16px !important;
      height: 16px;
      width: 16px;
      line-height: 12px;
      margin-top: -14px;
    }
  }
}

.table-btn {
  padding: 12px 16px !important;
  background: #fabb18;
  font-size: 14px;
  border-radius: 10px !important;

  &.export {
    margin-right: 16px;
    padding: 8px 0px !important;
    margin-right: 16px;
    min-width: 50px;
  }
}

.underline-btn {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.account-tree {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  .tree-item {
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    background: white;
    height: 46px;

    .xsTitle-f {
      top: 1px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .cdk-drag {
    display: flex;

    // height: 46px;
    .cdk-drag-handle {
      background: #e7e7e7;
      border-radius: 0px;
      height: 46px;
      width: 46px;
    }
  }
}

.cdk-drop-list {
  & > .ng-star-inserted {
    // margin-bottom: 10px;
  }

  & > .cdk-drop-list {
    & > .ng-star-inserted {
      // margin-left: 10px;
    }
  }
}

.mat-drawer-opened + div + .mat-drawer-content {
  .header {
    @media only screen and (min-width: $tablet-min) {
      // width: calc(100% - 320px) !important;
      transition: all 400ms;
      right: 0;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }
}

.logo-container {
  text-align: center;
}

.filter-wrapper {
  padding-top: 16px;

  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.clear-filter-btn {
  padding: 12px 16px !important;
  font-size: 14px;
  margin: 10px auto 0px !important;
  display: block !important;
  background: #f2f2f2;
  border-radius: 10px !important;
}

.cashbook-create {
  background: white;
  padding: 16px 0px;
}

.prefix-selection {
  position: relative;

  .mat-chip-list-wrapper {
    margin-bottom: 12px;
  }
}

.desktop-page-header {
  @media only screen and (max-width: $mobile-max) {
    display: none !important;
  }

  margin-bottom: 16px;

  .bread-crumb {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #a6a6a6;

    b {
      color: black;
    }

    .mat-icon {
      font-size: 18px;
      height: 16px;
      width: 16px;
      line-height: 16px;
      vertical-align: middle;
      margin: 0px 6px;
      position: relative;
      top: -2px;
    }
  }

  .mat-mini-fab {
    background: white;
    margin-left: 12px;
    color: black;
    box-shadow: 0px 3px 16px 0 #c1c5e445 !important;

    &:first-child {
      margin-left: 0px;
    }
  }

  &.multi-select {
    @media only screen and (max-width: $mobile-max) {
      display: flex !important;
      margin-top: 16px;
    }
  }
}

.mat-select-arrow {
  margin-top: 8px !important;
}

.m-r-6 {
  margin-right: 6px;
}

@media (orientation: landscape) {
  .mat-datepicker-content-touch .mat-datepicker-content-container {
    width: 50vh !important;
    height: fit-content !important;
  }
}

.mat-calendar-body-selected {
  color: white !important;
}

.mat-menu-item {
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selective mat-icon {
  color: unset;
}

.current-company {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;

  padding: 4px 6px;

  @media only screen and (min-width: $tablet-min) {
    background: #f2f2f2;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 6px;
  }
}

.m-t-10 {
  margin-top: 10px;
}

.pulsing {
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #4743e3;
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #4743e3;
    box-shadow: 0 0 0 0 #4743e3;
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.pie-chart {
  height: 250px;
  margin-bottom: 0px !important;

  @media only screen and (max-width: $mobile-max) {
    height: 135px;
  }
}

.bar-chart {
  height: 181px;
  margin-bottom: 0px !important;
}

.mat-card-subtitle {
  font-weight: 600;
}

.card-filter-btn {
  background: #f2f2f2;
  border-radius: 10px !important;
  position: absolute !important;
  right: 16px;
  font-weight: 500;
  padding: 8px 12px !important;

  .mat-icon {
    height: 18px;
    width: 18px;
    font-size: 18px;
    position: relative;
    top: -1px;
  }
}

.mobile-quick {
  display: flex;
  width: 100%;
  background: linear-gradient(180deg, #4743e3, #2870fa);
  padding: 16px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  @media only screen and (min-width: $tablet-min) {
    display: none;
  }

  & > div {
    width: 25%;
    text-align: center;
    font-size: 10px;
    color: white;

    mat-icon {
      color: #fabb18;
    }
  }

  .add-circle {
    .mat-icon {
      width: 42px;
      height: 42px;
      font-size: 42px;
    }
  }
}

.slide-card {
  @media only screen and (max-width: $mobile-max) {
    margin: -16px -16px 16px !important;
    box-shadow: none !important;
  }
}

.year-end-picker .mat-calendar-table-header {
  display: none;
}

.mat-bottom-sheet-container-medium {
  min-width: 480px !important;
}

.past-text {
  font-size: 10px;
  color: #a6a6a6;
}

.mat-card-actions {
  button {
    padding: 12px !important;
    line-height: 18px !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;

    &.mat-primary {
      background-color: #fabb18;
      color: black;
    }
  }
}

.show-desktop {
  @media only screen and (max-width: $tablet-max) {
    display: none !important;
  }
}

.show-tablet {
  @media only screen and (max-width: $mobile-max) {
    display: none !important;
  }
}

.btn-in-option {
  font-weight: 600;

  mat-icon {
    margin-right: 8px !important;
  }
}

.mat-icon-button i,
.mat-icon-button .mat-icon {
  top: -2px;
  position: relative;
}

.show-mobile {
  @media only screen and (min-width: $tablet-min) {
    display: none !important;
  }
}

.show-all {
  display: block !important;
}

.scroll-container {
  // overflow: auto;
  // max-height: calc(100vh - 138px);
  @media only screen and (max-width: $mobile-max) {
    padding: 16px 16px 0px;
    margin: 0px -16px;
  }

  @media only screen and (min-width: $tablet-min) {
    box-shadow: 0px 3px 6px 1px #c1c5e46b !important;
    max-height: calc(100vh - 200px);
  }

  &.card {
    box-shadow: none !important;
    // max-height: calc(100vh - 322px) !important;
    overflow-x: auto;

    @media only screen and (min-width: $tablet-min) {
      padding: 0px 16px 0px 16px;
      margin: 0px -16px 0px -16px;
    }
  }

  .mat-card {
    box-shadow: none !important;
    margin: 0px;
    padding: 0px;
  }
}

.doc-paging {
  margin-bottom: 10px;

  .mat-flat-button {
    background: none;
    padding: 12px 16px !important;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      background: #e5e5e5;
    }
  }

  b {
    font-size: 16px;
    padding: 16px;
    font-weight: 700;
    position: relative;
    top: 1px;
  }

  mat-icon {
    color: #898989;
  }
}

.ngx-file-drop__drop-zone {
  border: none !important;
  border-radius: 0px !important;
  width: 100%;
  left: 0;
  position: absolute;
  height: 100% !important;
  min-height: calc(100vh - 216px) !important;
  margin-top: -16px !important;
}

.ngx-file-drop__drop-zone--over {
  background: #eef0f8 !important;
  border: 3px solid #0c67ff !important;
}

.mat-mini-fab .mat-button-wrapper {
  position: relative;
  top: -2px;
}

.content-sidenav {
  background: none;

  .folder-container {
    padding: 16px;

    cdk-nested-tree-node {
      cursor: pointer;

      .node-name {
        position: relative;
        font-weight: 500;
      }
    }

    .mat-button-disabled + .node-name {
      top: 4px;
    }

    .mat-flat-button {
      width: 100%;
      padding: 12px 16px !important;
      background: #fabb18;
      font-size: 14px;
      border-radius: 10px !important;
      margin-top: 16px;

      &.mat-button-disabled {
        background: #f2f2f2;
      }
    }
  }

  .mat-drawer {
    min-width: 300px;
  }

  .mat-drawer-content {
    height: calc(100vh - 64px) !important;
    // overflow: unset;
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgb(255 255 255 / 60%);
  }

  .mat-drawer-inner-container {
    // background-color: #f2f2f2 !important;
    .xsTitle-f {
      padding: 16px;
      background: #f2f2f2;
    }
  }
}

.upload-mention {
  width: fit-content;
  position: fixed !important;
  margin: 0 auto;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  background: #0c67ff;
  color: white;
}

.upload-banner {
  position: fixed !important;
  bottom: 0;
  right: 23px;
  width: 300px;

  .mat-expansion-panel-header {
    padding: 0px 24px 0px 16px;
    background: #fabb18 !important;
    border-radius: 0px;
    height: 100%;
    min-height: 54px;
  }

  .mat-expansion-panel-header-description {
    flex-grow: unset !important;
  }

  .mat-expansion-panel-header-title {
    align-items: center;
  }

  .mat-expansion-panel-body {
    padding: 0px;
  }

  .period {
    background: #f2f2f2;
    padding: 10px 16px;
    background: #eef0f8;
  }

  .mat-list-text {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .mat-progress-spinner {
    height: 24px !important;
    width: 24px !important;

    svg {
      height: 24px !important;
      width: 24px !important;
    }
  }

  .cancel-btn {
    right: -8px;

    &:hover {
      background: #f2f2f2;
    }
  }

  .cancel-all-btn {
    padding: 0px !important;
    position: relative;
    right: -2px;
  }

  .mat-expansion-indicator {
    transform: rotate(180deg) !important;
    bottom: -3px;
    position: relative;
  }

  &.mat-expanded {
    .mat-expansion-indicator {
      transform: rotate(0deg) !important;
      bottom: 2px;
    }
  }

  .uploading {
    .cancel-btn {
      display: none;
    }

    &:hover {
      .cancel-btn {
        display: block;
      }

      .mat-progress-spinner {
        display: none;
      }
    }
  }

  .spinner-bg {
    position: absolute;
    right: 16px;

    circle {
      stroke: #f2f2f2;
    }
  }

  .dismiss-btn {
    margin-right: -10px;
  }
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0px !important;
}

.with-hint {
  margin-bottom: 16px !important;
}

.form-page {
  @media only screen and (max-width: $mobile-max) {
    width: 100%;
    // position: fixed;
    // overflow: scroll;
    height: 100%;
    top: 0;

    .page-header {
      position: sticky;
    }
  }
}

.without-pad {
  @media only screen and (max-width: $mobile-max) {
    padding: 0px;
  }
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-plan {
  border-radius: 100pc;
  background: #f2f2f2;
  font-size: 14px;
  padding: 4px 16px;
  width: fit-content;
  margin: 0 auto 10px;
  font-weight: 600;

  &.gold {
    background: linear-gradient(45deg, #fabb18, #ffec63);
  }
}

.unlimit-plan {
  border: 2px solid #fabb18 !important;
}

.head-setting {
  background: #f2f2f2;
  margin-left: 16px !important;
}

.active-tree {
  background-color: #f2f2f2;
}

.m-l-10 {
  margin-left: 10px;
}

.select-aging {
  .mat-form-field-wrapper {
    padding: 0px !important;
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-infix {
    padding: 0px !important;
  }

  .mat-select-arrow-wrapper {
    position: relative;
    top: -2px;
  }
}

.scroll-table {
  overflow: auto;
  display: block !important;
}

.p-t-0 {
  padding-top: 0px;
}

.bal-col {
  border-left: 5px solid #f2f2f2;
}

.table-list {
  td.bal-month:nth-last-child(2),
  th.bal-month:nth-last-child(2) {
    padding-right: 16px !important;
  }
}

.sm-col {
  width: 100px;
}

.medium-col {
  width: 150px;
}

.x-medium-col {
  width: 180px;
}

.x-lg-col {
  width: 200px;
}

.lg-col {
  width: 250px;
}

.img-container {
  height: 40vh;
}


.mat-bottom-sheet-container {
  min-width: 100vw !important;
  min-height: 100vh !important;
  padding: 0px !important;
  .register-img{
    object-fit: cover;
  }
}
.register-sheet {
  background: white;
  padding-top: 0px !important;

  // min-width: 100vw !important;
  // min-height: 100vh !important;
  .register-img {
    width: 100%;
    height: 841px;
    margin-bottom: -530px;
    //object-fit: cover;
  }
  .form-field {
    max-width: 420px;
    margin: 0 auto;
    z-index: 1000;
    position: relative;
    margin-top: 15px;
    .mat-checkbox-label {
      color: white;
      font-size: 16px;
    }
    .mat-checkbox-inner-container {
      height: 20px;
      width: 20px;
      margin-right: 12px;
    }
    .mat-checkbox-checked .mat-checkbox-background {
      background-color: #f6c867 !important;
    }
    .mat-checkbox-frame {
      border: 1px solid white;
    }
  }
  .container-bg {
  }
}

.welcome {
  text-align: center;
  margin-bottom: 24px;
  color: #7a706f !important;
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
}

.seat-container {
  color: white;
  display: flex;
  align-items: start;
  margin-left: 32px;
}

.register-label {
  font-size: 20px;
  font-weight: bold;
  margin-right: 8px;
}

.register-seat {
  font-size: 65px;
  color: #f6c867;
  font-weight: bold;
  line-height: 48px;
}

.register-seat-container {
  display: flex;
  justify-content: center;
  margin: 80px 0px;
}

.register-seat {
  font-size: 25px;
  font-weight: bold;
  line-height: 125px;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  padding: 10px;
  background: transparent;
  border: 3px solid #7a706f;
  color: #7a706f;
  text-align: center;
}

.full-btn {
  width: 100%;
  background-color: #fefeff;
  color: #3f3f3f;
  padding: 24px;
  font-size: 20px;
  font-weight: bold;
  margin: 24px 0px;
}

.register-seat-container {
  display: flex;
  justify-content: center;
  margin: 55px 0px;
}

.register-sheet .form-field .mat-checkbox-checked .mat-checkbox-background {
  background-color: #fefeff !important;
}
.register-sheet .form-field .mat-checkbox-frame {
  border: 2px solid #7a706f !important;
}
.mat-checkbox-checkmark-path {
  stroke: #000000 !important;
}

.mat-bottom-sheet-container {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.close {
  background: #ffffff;
}

.close span {
  background: #101010;
  box-shadow: 0 0 20px -5px rgb(0 0 0 / 50%);
}

.close .circle path {
  stroke: black;
}
